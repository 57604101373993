import React from 'react';
import withLoading from './hocs/LoadingHOC';
import Home from '../../assets/customization/Home';

interface WelcomePageProps {
  loading: boolean;
  onTabChange: (tab: string) => void;
}

interface Option {
  number: number;
  description: string;
  link: string;
  hasRibbon: boolean;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ loading, onTabChange }) => {
  const Loading = withLoading("div");

  const options: Option[] = [
    { number: 1, description: "Carousel", link: `top-slider`, hasRibbon: false },
    { number: 2, description: "4 Categories Section", link: `categories`, hasRibbon: true },
    { number: 3, description: "Banner 1 landscape", link: `banner-landscape`, hasRibbon: true },
    { number: 4, description: "2 Categories Section", link: `central-categories`, hasRibbon: true },
    { number: 5, description: "Values - Why To Choose Us", link: `values`, hasRibbon: true },
    { number: 6, description: "Banner 2 (Text + Picture banner)", link: `banner-footer`, hasRibbon: true },
    { number: 7, description: "Tagline", link: `tagline`, hasRibbon: false },
  ];

  return (
    <Loading isFetching={loading}>
      <div className="container welcome">
        <div className="row topBar"></div>
        <div className="row titleHeader"></div>
        <div className="wrapperContent">
          <div className="row">
            <div className="col-sm col-left">
              <h1>Admin Edit</h1>
              <h2>Configurable sections of the Homepage</h2>
              <p className="font-13 mt-4">
                Welcome, these are the sections you can edit and customize on your Homepage.<br />
                Please click on the area you want to edit or access them from the color menu.
              </p>
              <div className="sectionsMenu mt-5">
                {options.map((option, index) => (
                  <OptionButton
                    key={index}
                    number={option.number}
                    description={option.description}
                    hasRibbon={option.hasRibbon}
                    onClick={() => onTabChange(option.link)}
                  />
                ))}
              </div>
            </div>
            <div className="col-sm col-right text-right">
              <Home onTabChange={onTabChange}/>
            </div>
          </div>
        </div>
      </div>
    </Loading>
  );
}

const getNumberWord = (num: number): string => {
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];
  return words[num - 1];
};

interface OptionButtonProps {
  number: number;
  description: string;
  hasRibbon: boolean;
  onClick: () => void;
}

const OptionButton: React.FC<OptionButtonProps> = ({ number, description, hasRibbon, onClick }) => (
  <a onClick={onClick}>
    <div className={`numberOption__wrapper ${getNumberWord(number)}`}>
      <div className="numberOption__number">
        {number}
      </div>
      <div className="numberOption__description">
        <p>{description}</p>
      </div>
      {hasRibbon && (
        <div className="ribbon-customization">
          <span>soon</span>
        </div>
      )}
    </div>
  </a>
);

export default WelcomePage;