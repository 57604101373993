import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TabContainerHOC from './hocs/TabContainerHOC';
import Notification from './hocs/NotificationHOC';
import { PageProps } from './TopSliderPage';

export default function TaglinePage({ model }: PageProps) {
    const [tagline, setTagline] = useState('');
    const [url, setUrl] = useState('');
    const [span, setSpan] = useState('');
    const [useUrl, setUseUrl] = useState(false);
    const [originalData, setOriginalData] = useState<{ tagline: string; url: string; span: string; useUrl: boolean } | null>(null);
    const [calling, setCalling] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [notification, setNotification] = useState<{ message: string | null; type: 'success' | 'error' }>({
        message: null,
        type: 'success',
    });

    const fetchTagline = async () => {
        setCalling(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}ws/Customization/GetMetafield/Tagline`, {
                headers: {
                    accept: '*/*',
                    'WS-API-KEY': process.env.REACT_APP_WS_API_KEY,
                    'Content-Type': 'application/json',
                    'WS-STORE-ID': model.storeId,
                },
            });
            const { p, url, span, useUrl } = response.data;
            const initialData = { tagline: p || '', url: url || '', span: span || '', useUrl: useUrl || false, text1: p || '', text2: span || '' };
            setTagline(initialData.tagline);
            setUrl(initialData.url);
            setSpan(initialData.span);
            setUseUrl(initialData.useUrl);
            setOriginalData(initialData);
        } catch (error) {
            console.error('Error fetching tagline:', error);
            setNotification({ message: 'Failed to load tagline. Please try again later.', type: 'error' });
        } finally {
            setCalling(false);
        }
    };

    useEffect(() => {
        fetchTagline();
    }, []);

    const handleTaglineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTagline(e.target.value);
        setHasChanges(checkForChanges(e.target.value, url, span, useUrl));
    };

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
        setHasChanges(checkForChanges(tagline, e.target.value, span, useUrl));
    };

    const handleSpanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSpan(e.target.value);
        setHasChanges(checkForChanges(tagline, url, e.target.value, useUrl));
    };

    const handleUseUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUseUrl(e.target.checked);
        setHasChanges(checkForChanges(tagline, url, span, e.target.checked));
    };

    const checkForChanges = (newTagline: string, newUrl: string, newSpan: string, newUseUrl: boolean) => {
        if (!originalData) return false;
        return newTagline !== originalData.tagline || newUrl !== originalData.url || newSpan !== originalData.span || newUseUrl !== originalData.useUrl;
    };

    const showNotification = (message: string, type: "success" | "error") => {
        setNotification({ message, type });
    };

    const saveTagline = async () => {
        if (!tagline.trim()) {
            setNotification({ message: 'Text 1 cannot be empty.', type: 'error' });
            return;
        }

        if (!hasChanges) {
            showNotification("No changes detected.", "error");
            return;
        }

        setCalling(true);
        setNotification({ message: null, type: 'success' });
        try {
            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}ws/Customization/UpsertMetafield/Tagline`,
                { p: tagline, span, url, useUrl },
                {
                    headers: {
                        accept: '*/*',
                        'WS-API-KEY': process.env.REACT_APP_WS_API_KEY,
                        'Content-Type': 'application/json',
                        'WS-STORE-ID': model.storeId,
                    },
                }
            );
            setNotification({ message: 'Tagline saved successfully!', type: 'success' });
            setOriginalData({ tagline, url, span, useUrl });
            setHasChanges(false);
        } catch (error) {
            console.error('Error saving tagline:', error);
            setNotification({ message: 'Failed to save tagline. Please try again.', type: 'error' });
        } finally {
            setCalling(false);
        }
    };

    const handleCancel = () => {
        if (originalData) {
            setTagline(originalData.tagline);
            setUrl(originalData.url);
            setSpan(originalData.span);
            setUseUrl(originalData.useUrl);
            setHasChanges(false);
        }
    };

    return (
        <TabContainerHOC
            title="Tagline"
            description={<Description />}
            containerClass="tagline"
            loading={calling}
        >
            <div className="notification-container">
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: null, type: "success" })}
                />
            </div>

            <div className="row mt-5">
                <div className="col-sm col-left">
                    <h2>Edit the Site Tagline</h2>
                </div>
            </div>

            <div className="tagline-input-wrapper mt-4">
                <p>
                    <b>Text 1</b>
                    <span className="esl"> (If a URL is enabled, this text will be a clickable link)</span>
                </p>
                <input
                    type="text"
                    value={tagline}
                    onChange={handleTaglineChange}
                    className="input-round form-control mt-2"
                    placeholder="Type your tagline here."
                    disabled={calling}
                />
            </div>

            <div className="tagline-input-wrapper mt-4 url">
                <div className="tagline-input-field">
                    <p>
                        <b>URL</b>
                        <span className="esl"> (Must be a valid URL)</span>
                    </p>
                    <input
                        type="text"
                        value={url}
                        onChange={handleUrlChange}
                        className="input-round form-control mt-2"
                        placeholder="Enter the associated URL here."
                        disabled={calling}
                    />
                </div>
                <div className="tagline-checkbox-field">
                    <p>
                        <b>Enable URL</b>
                    </p>
                    <input
                        type="checkbox"
                        checked={useUrl}
                        onChange={handleUseUrlChange}
                        className="mt-2"
                        disabled={calling}
                    />
                </div>
            </div>

            <hr className="mt-4" />

            <div className="tagline-input-wrapper mt-4">
                <p>
                    <b>Text 2</b>
                    <span className="esl"> (This text will appear to the left of Text 1)</span>
                </p>
                <input
                    type="text"
                    value={span}
                    onChange={handleSpanChange}
                    className="input-round form-control mt-2"
                    placeholder="Enter the span value here."
                    disabled={calling}
                />
            </div>

            <div className="navigationFooter mt-5">
                <a className="backBtn" onClick={handleCancel}>
                    CANCEL
                </a>
                <span className={`saveBtn ${calling ? 'disabled' : ''}`} onClick={saveTagline}>
                    SAVE
                </span>
            </div>
        </TabContainerHOC>
    );
}

const Description = () => (
    <ul>
        <li>Provide a short text labeled as <b>Text 1</b>, with a recommended maximum of 300 characters.</li>
        <li>You can also include an additional text block, labeled as <b>Text 2</b>.</li>
        <li>Optionally, associate a valid <b>URL</b> with Text 1, which will be linked accordingly.</li>
    </ul>
);