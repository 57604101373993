import React, { ReactNode } from "react";

const nav = `.`;

interface LayoutHOCProps {
  mainContent: ReactNode;
  header?: boolean;
  onTabChange: (tab: string) => void;
  activeTab: string;
}

interface TabProps {
  className: string;
  label: string;
  ribbon?: boolean;
  isActive: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ className, label, ribbon, isActive, onClick }) => {
  return (
    <div 
      className={`col ${className} ${isActive ? 'active' : ''}`} 
      onClick={onClick}
    >
      {ribbon && (
        <div className="ribbon-customization">
          <span>soon</span>
        </div>
      )}
      {label}
    </div>
  );
};

const LayoutHOC: React.FC<LayoutHOCProps> = ({ mainContent, header = true, onTabChange, activeTab}) => {
  const tabs = [
    { className: "welcome", label: "welcome"},
    { className: "top-slider", label: "carousel"},
    { className: "tagline", label: "tagline" },
    { className: "categories", label: "4 categories", ribbon: true },
    { className: "banner-landscape", label: "banner 1", ribbon: true },
    { className: "central-categories", label: "2 categories", ribbon: true },
    { className: "values", label: "values", ribbon: true },
    { className: "banner-footer", label: "banner 2", ribbon: true },
  ];

  return (
    <>
      {header && (
        <div className="header container text-center">
          <div className="row align-items-start">
            {tabs.map((tab) => (
              <Tab
                key={tab.className}
                className={tab.className}
                label={tab.label}
                ribbon={tab.ribbon}
                isActive={activeTab === tab.className}
                onClick={() => onTabChange(tab.className)}
              />
            ))}
          </div>
        </div>
      )}
      <div className="customization-tool-body">{mainContent}</div>
    </>
  );
};

export default LayoutHOC;
